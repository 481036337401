@font-face {
  font-family: 'Source Sans Pro';
  src: url(./fonts/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #a68eff;
  border-radius: 10px;
}

.lang-select .ant-select-selector,
.lang-select .ant-select-arrow{
  color: white !important;
}

/* .ant-table-rounded .ant-table{
  border-radius: 8px !important;
  overflow: hidden;
} */

.ant-table-shadow .ant-table{
  filter: drop-shadow(rgba(170, 170, 170, 0.333) 0px 0.1em 0.5em);
}

/* .ant-table-placeholder .ant-table-cell {
  border: none !important;
} */

.ant-input-group-addon {
  padding: 0 !important;
}

h1, h2, h3, h4, h5 {
  margin-block-start: 0;
}